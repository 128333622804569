.progress-bar {
    border-color: rgba(161, 227, 255, 0.5);
    border-radius: 1px;
    border-style: solid;
    border-width: 2px;
    width: 100%;
    height: 2em;
    position: relative;
}

.progress-bar .inner-progress {
    height: 100%;
    padding: 3px 0 3px 0;
    width: 0%;
    background-color: rgba(161, 227, 255, 1);
    position: absolute;
    left: 0;
    top: 0;
}

.progress-bar .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}